.imageContainer{
    max-width: 500px;
    margin: 0 auto;
}
.textareaContainer{
    max-width: 500px;
    border-radius: 10px;
    padding:10px;

}
.textareaField{
    width:100%;
}
.uploadContainer{
    max-width: 500px;
    margin: 0 auto;
    width:100%;
    background: #efebed;
    border-radius: 10px;
}
.image{
    padding:5px;
    width:180px;
    vertical-align: middle;
    text-align: center;
    margin:0 auto;
}

.uploadGuidelines{
    max-width: 500px;
    margin: 0 auto;
    width:100%;
    background: whitesmoke;
}