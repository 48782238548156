img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  object-fit: cover;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

div.admin-bg {
  /* Set rules to fill background */
  min-height: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: 430px;

  /* Set up positioning */
  /*position: relative;*/
  background-image: url('https://images.seensta.com/media/coverphoto-landing.jpg');
  background-size: cover;
  background-position-y: -300px;
}

div.admin-bg-creative {
  /* Set rules to fill background */
  min-height: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: 430px;

  /* Set up positioning */
  /*position: relative;*/
  background-image: url('https://images.seensta.com/media/coverphoto-landing.jpg');
  background-size: cover;
  background-position-y: -300px;
}

.socialgood-container {
  height: 450px;

  background-image: url('https://images.seensta.com/folio/cvd7E3_IMG-20171121-WA0017.jpg');
  background-position: center;
  background-size: cover;
}

.socialgood-thegood {
  height: 300px;
  background: rgba(27, 65, 124, 0.87);
  border-radius: 10px;
  width: 90%;
  position: relative;
  top: 25px;
  margin: 0 auto;
}

.socialgood-title {
  color: white;
}
.socialgood-title span {
  font-size: 24px;
  display: block;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}

ul {
  list-style: none;
}

.whycare-container {
  height: 250px;
  background: '#fff';
}
.whycare-title {
  color: #000;
}
.whycare-title span {
  font-size: 24px;
  display: block;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}

.whycare-content {
  height: 300px;
  border-radius: 10px;
  width: 90%;
  position: relative;
  top: 25px;
  margin: 0 auto;
}

.whycare-quote {
  color: black;
  line-height: 1.5;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
}

.whycare-quote span {
  font-size: 30px;
  font-weight: 700;
  color: red;
  text-decoration: underline;
}

.landingcover {
  background: rgba(26, 46, 113, 0.26);
  height: 430px;
}

.tellstory-container {
  height: 311px;
  display: flex;
  background: black;
}

.tellstory-right {
  height: 222px;
  margin-top: 10px;
}
.tellstory-left img {
  width: 222px;
  border: 1px solid black;
}

.tellstory-text {
  padding: 15px;
  padding-right: 0px;
  background: black;
  color: white;
  display: inline-block;
  margin-top: 5px;
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer {
  color: #fff;
  background: rgb(176, 184, 195);
  padding: 25px;
}
.footer-content {
  max-width: 1320px;
  margin: 0 auto;
  color: #fff;
}

.welcome-toolbar {
  min-height: 45px;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.welcome-logo-width {
  width: 110px;
}

.signup-button-group {
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}

.signup-button-group-color {
  color: #000 !important;
}

.white-background {
  background-color: #fff;
}
.welcome-hero-container {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 30px;
}
.welcome-hero {
  position: absolute;
  top: 30%;
  max-width: 600px;
}

.welcome-hero-typography {
  padding-top: 20px;
  line-height: 1.4 !important;
  font-size: 36px !important;
  color: #454545 !important;
  font-weight: 300 !important;
}

.welcome-appstore-image {
  width: 150px;
}

.welcome-phone-width {
  width: 300px;
}
.errorContainer {
  display: block;
  font-weight: 600;
  text-align: center;
}
