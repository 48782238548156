.textInput{
    color:#000
}
Textarea{
    color:#000
}

#outlined-full-width2{
    color:#000;
    min-height: 100px;
}

#age-native-simple{
    color:#000;

}
#outlined-full-width3{
    max-width:500px;
    color:#000;
}