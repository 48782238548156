.questionsContainer{
    max-width: 1000px;
    margin: 0 auto;
    margin-top:45px;
    padding:10
}

.question{
    font-size: 1.5rem !important;
    font-weight: 100 !important;
    display: block;
    width:100%;
    text-align: center;
    padding-bottom: 0;
}

.iteratorButton{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:10px;
    margin-top:30px; 

}

.textArea{
    max-width: 500px;
    width:100%;
}

.chip{
    font-size: 18px !important;
    font-weight: 200;
}

.sizeDisplay{
    width: 340px;display: inline-flex;overflow-x: scroll;
}

.arrowSize{
    display:inline-flex;
    align-items: center;
    padding:10px;
}
.sizeHeadingName{
    text-align: left; padding: 10px;display:block;
}
.checkMarkClothes{
    position: relative;
    top:100px;
    color:#3ba579;
    z-index:800;
}
.clothesImageContainer{
    width: 150px;
}
.itemsImage{
    margin:0 auto;
    object-fit:contain;
}

.dateDisplay{
    padding: 20px;
    text-align: center;
    background-color: white;
    margin:20px;
}