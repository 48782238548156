.howitworks-container{
    padding:20px;
    margin:-7vh 16px 0px;
    border-radius: 0;
    background: #fff;
    color:#000;
    box-shadow: 0 3px 7px -8px rgba(72,72,72,0.5), 0 2px 4px 0 rgba(86,86,86,0.5);
    height: 100%;
    display: inline-block;

}

.how-container{
    margin-bottom:30px;
    color:#000;
    display: inline-block;

}

.howimage{
    float:left;
    width:30%;
    color:#000;
    height: 100px;
    padding:10;

}

.howimage2{
    float:left;
    width:30%;
    color:#000;
    font-size: 36px;
    text-decoration: underline;


}
.how-description{
    float: left;
    width:70%;
    color:#000;

}
.how-heading{
    color:#000;
    font-weight: 700;
    font-size:18px;
    line-height: 18px;
    padding-bottom: 7px;

}
.how-heading h5{
    font-size:18px;
    line-height: 18px;
    color:#000;

}
.howitworks-title {
    text-align: center;
    padding: 3rem 0;
}
.howitworks-title span {
    padding-top:30;
    margin:0 auto;
    line-height: 20px;
    font-size: 14px;
    text-transform: initial;
    margin-bottom:20px;
    color:#000;
    position: relative;
    text-align: center;
    font-weight: bold;
    width:100%;

}

.how-caption span {
    font-size: 16px;
    color:#25292C;
    line-height:22px;

}


.thegood-icons{
    width:35px;
    display: block;
    float:left;
}
.thegood-text{
    display:block;
}

.thegood-list{
    margin-bottom: 20px;
}
