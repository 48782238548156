.card-profile {
  display: flex;
  max-width: 700px;
  height: 350px;
  margin: '0 auto';
  position: relative;
  top: 20px;
}
details {
  display: 'flex';
  flex-direction: 'column';
}
.content {
  flex: '1 0 auto';
  width: 100%;
}
.cover {
  width: 251;
}

.about-space {
  padding-top: 15px;
}
.about-bio {
  font-size: 18;
}
.play-icon {
  height: 38;
  width: 38;
}
.avatar-card {
  width: 58px !important;
  height: 58px !important;
  /*margin: 0 auto;*/
}
.heading {
  flex-basis: 33.33%;
  flex-shrink: 0;
}
.card-container {
  text-align: center;
  background: #762359;
  padding-bottom: 20;
}

.card-profile-name {
  padding-bottom: 15px;
  text-align: center;
}

.profilecover {
  background-position: center;
  background-size: cover;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}

.profile-card-user {
  width: 1000px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-height: 400px;
  margin-top: 100px;
}

.profile-image {
  max-height: 400px;
  width: 500px;
  max-height: 500px;
  height: 100%;
}

.work-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
}

.work-line span {
  background: #000;
  padding: 0 10px;
  color: #fff;
}

.usertype-label {
  position: relative;
  top: 4px;
  float: right;
  background: #000 !important;
  color: #fff !important;
  right: 5px;
}

.usertype-avatarupload {
  position: relative;
  top: 4px;
  float: left;
  right: 5px;
}

.shareButtons {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 15px;
}
.padding20 {
  padding: 20px;
}
.padding50 {
  padding: 50px;
}
.socialIconButton {
  width: 30px;
  height: 30px;
}
.flex-grid-follow-container {
  display: inline-flex;
}
