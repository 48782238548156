.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid-mobile {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  padding: 10px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column_mobile {
  padding-left: 10px;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: #ffffff;
  margin-bottom: 30px;
}

.desktop-grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
}

.desktop-grid-containerflex {
  padding-right: 30px;
  flex: 1;
}
.desktop-grid-container-card {
  flex: 4;
}
.refresh {
  width: 100%;
  display: block;
  text-align: center;
  color: '#ccc';
  margin-top: -17px;
}
.center {
  text-align: center;
}

.desktop-width-100 {
  padding-bottom: 130px;
  max-width: 1380px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .card-grid-container {
    float: left;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .card-grid-container {
    float: left;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .card-grid-container-explore {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .card-grid-container-explore {
    float: left;
    padding-left: 20px;
  }
}

.fabGig {
  position: 'fixed';
  bottom: 2px;
  right: 2px;
}
