body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.imageContainer{
    max-width: 500px;
    margin: 0 auto;
}
.textareaContainer{
    max-width: 500px;
    border-radius: 10px;
    padding:10px;

}
.textareaField{
    width:100%;
}
.uploadContainer{
    max-width: 500px;
    margin: 0 auto;
    width:100%;
    background: #efebed;
    border-radius: 10px;
}
.image{
    padding:5px;
    width:180px;
    vertical-align: middle;
    text-align: center;
    margin:0 auto;
}

.uploadGuidelines{
    max-width: 500px;
    margin: 0 auto;
    width:100%;
    background: whitesmoke;
}
.textInput{
    color:#000
}
Textarea{
    color:#000
}

#outlined-full-width2{
    color:#000;
    min-height: 100px;
}

#age-native-simple{
    color:#000;

}
#outlined-full-width3{
    max-width:500px;
    color:#000;
}
.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid-mobile { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  padding: 10px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.my-masonry-grid_column_mobile {
  padding-left: 10px;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: #ffffff;
  margin-bottom: 30px;
}

.desktop-grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
}

.desktop-grid-containerflex {
  padding-right: 30px;
  flex: 1 1;
}
.desktop-grid-container-card {
  flex: 4 1;
}
.refresh {
  width: 100%;
  display: block;
  text-align: center;
  color: '#ccc';
  margin-top: -17px;
}
.center {
  text-align: center;
}

.desktop-width-100 {
  padding-bottom: 130px;
  max-width: 1380px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .card-grid-container {
    float: left;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .card-grid-container {
    float: left;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .card-grid-container-explore {
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .card-grid-container-explore {
    float: left;
    padding-left: 20px;
  }
}

.fabGig {
  position: 'fixed';
  bottom: 2px;
  right: 2px;
}

.bespokelabel{
    max-width: 200px;
    background: #fdce71;
    color: #762259;
    bottom: 50px;
    position: relative;
    text-transform: lowercase;
    padding: 10px;
    font-weight: 600;
}
img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  object-fit: cover;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

div.admin-bg {
  /* Set rules to fill background */
  min-height: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: 430px;

  /* Set up positioning */
  /*position: relative;*/
  background-image: url('https://images.seensta.com/media/coverphoto-landing.jpg');
  background-size: cover;
  background-position-y: -300px;
}

div.admin-bg-creative {
  /* Set rules to fill background */
  min-height: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: 430px;

  /* Set up positioning */
  /*position: relative;*/
  background-image: url('https://images.seensta.com/media/coverphoto-landing.jpg');
  background-size: cover;
  background-position-y: -300px;
}

.socialgood-container {
  height: 450px;

  background-image: url('https://images.seensta.com/folio/cvd7E3_IMG-20171121-WA0017.jpg');
  background-position: center;
  background-size: cover;
}

.socialgood-thegood {
  height: 300px;
  background: rgba(27, 65, 124, 0.87);
  border-radius: 10px;
  width: 90%;
  position: relative;
  top: 25px;
  margin: 0 auto;
}

.socialgood-title {
  color: white;
}
.socialgood-title span {
  font-size: 24px;
  display: block;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}

ul {
  list-style: none;
}

.whycare-container {
  height: 250px;
  background: '#fff';
}
.whycare-title {
  color: #000;
}
.whycare-title span {
  font-size: 24px;
  display: block;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}

.whycare-content {
  height: 300px;
  border-radius: 10px;
  width: 90%;
  position: relative;
  top: 25px;
  margin: 0 auto;
}

.whycare-quote {
  color: black;
  line-height: 1.5;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
}

.whycare-quote span {
  font-size: 30px;
  font-weight: 700;
  color: red;
  text-decoration: underline;
}

.landingcover {
  background: rgba(26, 46, 113, 0.26);
  height: 430px;
}

.tellstory-container {
  height: 311px;
  display: flex;
  background: black;
}

.tellstory-right {
  height: 222px;
  margin-top: 10px;
}
.tellstory-left img {
  width: 222px;
  border: 1px solid black;
}

.tellstory-text {
  padding: 15px;
  padding-right: 0px;
  background: black;
  color: white;
  display: inline-block;
  margin-top: 5px;
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer {
  color: #fff;
  background: rgb(176, 184, 195);
  padding: 25px;
}
.footer-content {
  max-width: 1320px;
  margin: 0 auto;
  color: #fff;
}

.welcome-toolbar {
  min-height: 45px;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.welcome-logo-width {
  width: 110px;
}

.signup-button-group {
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}

.signup-button-group-color {
  color: #000 !important;
}

.white-background {
  background-color: #fff;
}
.welcome-hero-container {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 30px;
}
.welcome-hero {
  position: absolute;
  top: 30%;
  max-width: 600px;
}

.welcome-hero-typography {
  padding-top: 20px;
  line-height: 1.4 !important;
  font-size: 36px !important;
  color: #454545 !important;
  font-weight: 300 !important;
}

.welcome-appstore-image {
  width: 150px;
}

.welcome-phone-width {
  width: 300px;
}
.errorContainer {
  display: block;
  font-weight: 600;
  text-align: center;
}

.howitworks-container{
    padding:20px;
    margin:-7vh 16px 0px;
    border-radius: 0;
    background: #fff;
    color:#000;
    box-shadow: 0 3px 7px -8px rgba(72,72,72,0.5), 0 2px 4px 0 rgba(86,86,86,0.5);
    height: 100%;
    display: inline-block;

}

.how-container{
    margin-bottom:30px;
    color:#000;
    display: inline-block;

}

.howimage{
    float:left;
    width:30%;
    color:#000;
    height: 100px;
    padding:10;

}

.howimage2{
    float:left;
    width:30%;
    color:#000;
    font-size: 36px;
    text-decoration: underline;


}
.how-description{
    float: left;
    width:70%;
    color:#000;

}
.how-heading{
    color:#000;
    font-weight: 700;
    font-size:18px;
    line-height: 18px;
    padding-bottom: 7px;

}
.how-heading h5{
    font-size:18px;
    line-height: 18px;
    color:#000;

}
.howitworks-title {
    text-align: center;
    padding: 3rem 0;
}
.howitworks-title span {
    padding-top:30;
    margin:0 auto;
    line-height: 20px;
    font-size: 14px;
    text-transform: initial;
    margin-bottom:20px;
    color:#000;
    position: relative;
    text-align: center;
    font-weight: bold;
    width:100%;

}

.how-caption span {
    font-size: 16px;
    color:#25292C;
    line-height:22px;

}


.thegood-icons{
    width:35px;
    display: block;
    float:left;
}
.thegood-text{
    display:block;
}

.thegood-list{
    margin-bottom: 20px;
}

.textInput{
    color:#000
}
Textarea{
    color:#000
}

#outlined-full-width{
    color:#000;
}

#outlined-full-width2{
    color:#000;
    width:950px;
}
.card-profile {
  display: flex;
  max-width: 700px;
  height: 350px;
  margin: '0 auto';
  position: relative;
  top: 20px;
}
details {
  display: 'flex';
  flex-direction: 'column';
}
.content {
  flex: '1 0 auto' 1;
  width: 100%;
}
.cover {
  width: 251;
}

.about-space {
  padding-top: 15px;
}
.about-bio {
  font-size: 18;
}
.play-icon {
  height: 38;
  width: 38;
}
.avatar-card {
  width: 58px !important;
  height: 58px !important;
  /*margin: 0 auto;*/
}
.heading {
  flex-basis: 33.33%;
  flex-shrink: 0;
}
.card-container {
  text-align: center;
  background: #762359;
  padding-bottom: 20;
}

.card-profile-name {
  padding-bottom: 15px;
  text-align: center;
}

.profilecover {
  background-position: center;
  background-size: cover;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1 1;
}

.profile-card-user {
  width: 1000px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-height: 400px;
  margin-top: 100px;
}

.profile-image {
  max-height: 400px;
  width: 500px;
  max-height: 500px;
  height: 100%;
}

.work-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
}

.work-line span {
  background: #000;
  padding: 0 10px;
  color: #fff;
}

.usertype-label {
  position: relative;
  top: 4px;
  float: right;
  background: #000 !important;
  color: #fff !important;
  right: 5px;
}

.usertype-avatarupload {
  position: relative;
  top: 4px;
  float: left;
  right: 5px;
}

.shareButtons {
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 15px;
}
.padding20 {
  padding: 20px;
}
.padding50 {
  padding: 50px;
}
.socialIconButton {
  width: 30px;
  height: 30px;
}
.flex-grid-follow-container {
  display: inline-flex;
}

.howitworks-container{
    padding:20px;
    margin:-7vh 16px 0px;
    border-radius: 0;
    background: #fff;
    color:#000;
    box-shadow: 0 3px 7px -8px rgba(72,72,72,0.5), 0 2px 4px 0 rgba(86,86,86,0.5);
    height: 100%;
    display: inline-block;

}

.how-container{
    margin-bottom:30px;
    color:#000;
    display: inline-block;

}

.howimage{
    float:left;
    width:30%;
    color:#000;
    height: 100px;
    padding:10;

}

.howimage2{
    float:left;
    width:30%;
    color:#000;
    font-size: 36px;
    text-decoration: underline;


}
.how-description{
    float: left;
    width:70%;
    color:#000;

}
.how-heading{
    color:#000;
    font-weight: 700;
    font-size:18px;
    line-height: 18px;
    padding-bottom: 7px;

}
.how-heading h5{
    font-size:18px;
    line-height: 18px;
    color:#000;

}
.howitworks-title {
    text-align: center;
    padding: 3rem 0;
}
.howitworks-title span {
    padding-top:30;
    margin:0 auto;
    line-height: 20px;
    font-size: 14px;
    text-transform: initial;
    margin-bottom:20px;
    color:#000;
    position: relative;
    text-align: center;
    font-weight: bold;
    width:100%;

}

.how-caption span {
    font-size: 16px;
    color:#25292C;
    line-height:22px;

}


.thegood-icons{
    width:35px;
    display: block;
    float:left;
}
.thegood-text{
    display:block;
}

.thegood-list{
    margin-bottom: 20px;
}

.questionsContainer{
    max-width: 1000px;
    margin: 0 auto;
    margin-top:45px;
    padding:10
}

.question{
    font-size: 1.5rem !important;
    font-weight: 100 !important;
    display: block;
    width:100%;
    text-align: center;
    padding-bottom: 0;
}

.iteratorButton{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:10px;
    margin-top:30px; 

}

.textArea{
    max-width: 500px;
    width:100%;
}

.chip{
    font-size: 18px !important;
    font-weight: 200;
}

.sizeDisplay{
    width: 340px;display: inline-flex;overflow-x: scroll;
}

.arrowSize{
    display:inline-flex;
    align-items: center;
    padding:10px;
}
.sizeHeadingName{
    text-align: left; padding: 10px;display:block;
}
.checkMarkClothes{
    position: relative;
    top:100px;
    color:#3ba579;
    z-index:800;
}
.clothesImageContainer{
    width: 150px;
}
.itemsImage{
    margin:0 auto;
    object-fit:contain;
}

.dateDisplay{
    padding: 20px;
    text-align: center;
    background-color: white;
    margin:20px;
}
.Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  .submit-button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    transition: all 150ms ease;
    margin:0 auto;
    display: block;
    margin-top: 10px;

  }

  form {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  .submit-button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

 
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::-webkit-input-placeholder {
    color: #aab7c4;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

