.textInput{
    color:#000
}
Textarea{
    color:#000
}

#outlined-full-width{
    color:#000;
}

#outlined-full-width2{
    color:#000;
    width:950px;
}